import React from "react"
import LatestNews from "../containers/latest-news"
import ThreeColumnGallery from "../containers/three-column-gallery"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import Blocks from "editorjs-blocks-react-renderer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { OutlinedButton, Next, Prev } from "../components/buttons"
// FOR EVENT Detail
import { EventDetailTitle, EventDetailDate } from "../components/typography"

const YellowBall = () => (
  <div className="bg-amber-400 h-3.5 w-3.5 rounded-full" />
)

// const style = {
//   header: {
//     h2: {
//       fontFamily: "Pilat Wide Book",
//       fontSize: "32px",
//       letterSpacing: "0.2px",
//       color: "#722F8B",
//       textAlign: "left",
//       fontWeight: 700,
//       marginTop: "35px",
//     },
//     h4: {
//       fontSize: "20px",
//       letterSpacing: "0.2px",
//       color: "black",
//       textAlign: "left",
//       fontWeight: 700,
//       marginTop: "35px",
//     },
//   },
//   paragraph: {
//     fontFamily: "Pilat Wide Book",
//     fontSize: "20px",
//     letterSpacing: "0.2px",
//     lineHeight: "38px",
//     color: "#722F8B",
//     marginBottom: "35px",
//   },
//   image: {
//     img: {
//       marginBottom: "35px",
//     },
//     figcaption: {
//       display: "none",
//     },
//   },
// }

const EventDetail = ({ data, location, pageContext }) => {
  console.log(data)
  // const Output = Loadable(() => import("editorjs-react-renderer"))
  const mainImage = getImage(data.strapiEvent.image.localFile)
  const myData = JSON.parse(data?.strapiEvent?.content?.data?.content)
  return (
    <Layout
      ctaColor="bg-yellowdark-50"
      textCtaColor="text-fontpurple-50"
      location={location}
      title={data.strapiEvent.title}
      fullHeader
    >
      <div className="bg-white">
        <Seo
          title={data.strapiEvent.title}
          description={data?.strapiEvent?.subtitle}
        />
        <GatsbyImage
          className="h-96 lg:h-[70vh] w-full"
          objectFit="cover"
          image={mainImage}
        />
        <div
          className="relative  bg-tealCustom-50 rounded-full text-white text-center -mt-12 max-w-7xl mx-auto
                 px-5
                 py-6
                 sm:px-20
                 sm:py-10
"
        >
          <div className="flex flex-row min-w-full justify-between items-center">
            <EventDetailTitle>{data.strapiEvent.title}</EventDetailTitle>
            <EventDetailDate>
              {data.strapiEvent.dateTimeFrom +
                " - " +
                data.strapiEvent.dateTimeTo}
            </EventDetailDate>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-4 p-6 max-w-7xl mx-auto py-6 sm:pl-20 sm:pr-7 sm:py-10">
          <div className="col-span-3 pb-20">
            <Blocks
              data={myData}
              config={{
                code: {
                  className: "language-js",
                },
                delimiter: {
                  className: "border border-2 w-16 mx-auto",
                },
                embed: {
                  className: "border-0",
                },
                header: {
                  className: "font-primary-bold pb-4",
                },
                image: {
                  className: "w-full pb-4 ",
                  actionsClassNames: {
                    stretched: "w-full h-80 object-cover",
                    withBorder: "border border-2",
                    withBackground: "p-2",
                  },
                },
                list: {
                  className: "list-inside",
                },
                paragraph: {
                  className: "text-left text-lg pb-4 font-inter",
                },
                figcaption: {
                  className: "hidden",
                },
                quote: {
                  className: "py-3 px-5 italic font-serif",
                },
                table: {
                  className: "table-auto",
                },
              }}
            />
            <div className="lg:h-20" />
            {data.strapiEvent.lineUp.length > 0 && (
              <p className=" font-primary-bold text-fontpurple-50 text-3xl mt-8">
                Line up
              </p>
            )}
            <div>
              {data.strapiEvent.lineUp.map(dj => (
                <div className="flex flex-row nowrap items-baseline">
                  <YellowBall />
                  <p
                    key={dj.artistName}
                    className="font-bold text-fontpurple-50 font-primary-bold text-xl mt-4 ml-4"
                  >
                    {dj.artistName}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:col-start-5 lg:col-end-6 lg:flex lg:flex-col lg:justify-between">
            <div className="flex flex-col h-full">
              <div className="lg:sticky lg:top-24">
                <a href="mailto:hi@zrcebeach.com" target="_blank">
                  <OutlinedButton
                    additionalClassName="text-fontpurple-50 font-primary-bold border-fontpurple-50 h-12
                              mt-10"
                  >
                    Book a table
                  </OutlinedButton>
                </a>
                <a
                  href={data?.strapiEvent?.buyTicketsLink || ""}
                  target="_blank"
                >
                  <OutlinedButton additionalClassName="font-primary-bold bg-fontpurple-50 mt-3 h-12">
                    Buy tickets
                  </OutlinedButton>
                </a>
              </div>
              <div className="lg:hidden flex nowrap items-center justify-between mt-16 mb-0 w-11/12 mx-auto">
                {pageContext.prevSlug ? (
                  <Link to={`../${pageContext.prevSlug}`}>
                    <Prev />
                  </Link>
                ) : (
                  <div className="w-8 bg-transparent"></div>
                )}
                <h3 className="font-primary-bold text-purplebglight-50">
                  Event
                </h3>
                {pageContext.nextSlug ? (
                  <Link to={`../${pageContext.nextSlug}`}>
                    <Next />
                  </Link>
                ) : (
                  <div className="w-8 bg-transparent"></div>
                )}
              </div>
            </div>
            <div className="flex  nowrap items-center justify-between mt-10 w-11/12 mx-auto">
              {pageContext.prevSlug ? (
                <Link to={`../${pageContext.prevSlug}`}>
                  <Prev />
                </Link>
              ) : (
                <div className="w-8 bg-transparent"></div>
              )}
              <h3 className="font-primary-bold text-purplebglight-50">Event</h3>
              {pageContext.nextSlug ? (
                <Link to={`../${pageContext.nextSlug}`}>
                  <Next />
                </Link>
              ) : (
                <div className="w-8 bg-transparent"></div>
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <LatestNews
        eventsCarouselBg="white"
        eventsCarouselFontColor="#009ADE"
        cardBorderColorClass="border-white-50"
        cardFontColorClass="text-white"
        type={"mainPage"}
        blogs={data.allStrapiBlog.edges}
      />
      <ThreeColumnGallery
        bgGallery="bg-black"
        titleColor={"text-white"}
        galleries={data.allStrapiGallery.nodes}
        galleryCtaTextColor={"text-white"}
        galleryCtaBorderColor={"border-white"}
      />
    </Layout>
  )
}

export default EventDetail

export const eventQuery = graphql`
  query event($id: Int!) {
    strapiEvent(strapi_id: { eq: $id }) {
      id
      slug
      title
      subtitle
      buyTicketsLink
      lineUp {
        artistName
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
      dateTimeFrom(formatString: "DD MMM")
      dateTimeTo(formatString: "DD MMM")
      content {
        data {
          content
        }
      }
    }
    allStrapiBlog {
      edges {
        node {
          slug
          featuredImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: DOMINANT_COLOR, quality: 100)
              }
            }
          }
          category {
            slug
          }
          title
          publishedAt
        }
      }
    }
    allStrapiGallery(limit: 3) {
      nodes {
        id
        title
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 10)
            }
          }
        }
      }
    }
  }
`
